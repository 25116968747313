import i18next from 'i18next';
import kebabCase from 'lodash/kebabCase';
import { twMerge } from 'tailwind-merge';

import { colors } from 'freely-shared-design';
import { useDestinationsStore } from 'freely-shared-stores';
import { TripDestination } from 'freely-shared-types';
import { getSearchSuggestions, isSuggestedDestinationSelected } from 'freely-shared-utils';

import { Svg } from '@assets/svg';
import { SectionChildrenWrapper } from '@components/sectionChildrenWrapper';
import { testProps } from '@utils/testProps';

import { Text } from '../text';

const DestinationName = ({
  destination,
  searchValue,
}: {
  destination: TripDestination;
  searchValue: string;
}) => {
  const escapedSearchValue = searchValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const matchingString = new RegExp(escapedSearchValue, 'i').exec(destination.longName)?.[0] ?? '';
  return (
    <div className={twMerge('grow')}>
      <Text variant="body-16/r">{destination.isCity && destination.countryName}</Text>
      <Text variant="body-16/r" className="inline">
        {destination.longName.split(matchingString).map((part, index) => {
          if (index / 2 !== 0) {
            return (
              <Text key={`${part}${index}`} variant="body-16/r" className="inline">
                <Text variant="body-16/sb" className="inline">
                  {new RegExp(escapedSearchValue, 'i').exec(destination.longName)?.[0] ?? ''}
                </Text>
                {part}
              </Text>
            );
          }
          return (
            <Text key={`${part}${index}`} variant="body-16/r" className="inline">
              {part}
            </Text>
          );
        })}
      </Text>
    </div>
  );
};

export interface DestinationSuggestionProps {
  searchValue: string;
  onClick: (destination: TripDestination) => void;
  selectedDestinations: TripDestination[];
  onRemoveSelectedDestination: (destination: TripDestination) => void;
}
export const DestinationSuggestion = ({
  searchValue,
  onClick,
  selectedDestinations,
  onRemoveSelectedDestination,
}: DestinationSuggestionProps) => {
  const destinations = useDestinationsStore(state => state?.destinations ?? []);
  const searchResults = getSearchSuggestions(searchValue, destinations);
  const isSearchValueEmpty = searchResults.length < 1;

  return (
    <SectionChildrenWrapper className="lg:border lg:border-cabo-200 lg:shadow-lg rounded-2xl bg-mono-100 w-full lg:w-[43%] py-4 lg:px-4 lg:py-4 overflow-hidden">
      <div className={twMerge('border-t max-h-1/2 lg:border-t-0 pt-4 lg:pt-0')}>
        <ul>
          {searchResults.map(destination => {
            const isSelected = isSuggestedDestinationSelected(destination, selectedDestinations);
            return (
              <li
                {...testProps(`${kebabCase(destination.longName)}`)}
                key={destination.longName}
                className={twMerge(
                  'px-2 cursor-pointer hover:bg-nusa-50 hover:rounded-lg py-3',
                  isSelected && 'bg-nusa-50 rounded-lg my-1 ',
                )}
                onClick={() => {
                  if (isSelected) return;
                  onClick(destination);
                }}>
                <div className={twMerge('flex flex-row space-x-4 items-center')}>
                  <div className={twMerge('rounded-md h-5 w-8')}>
                    {!!destination.countryFlagImage && (
                      <img
                        src={destination.countryFlagImage}
                        className={twMerge('rounded-md w-8 shadow-md')}
                        alt={destination.countryName}
                      />
                    )}
                  </div>
                  <DestinationName destination={destination} searchValue={searchValue} />
                  {isSelected && (
                    <div
                      className={twMerge(
                        'basis-[30%] pr-3 flex flex-row items-center justify-end',
                      )}>
                      <Text variant="body-16/r" className="text-fuji-800 pt-0.5">
                        Added
                      </Text>
                      <Svg.CloseCircleLight
                        fill={colors.fuji[800]}
                        className="w-4 ml-2 mt-0.5 cursor-pointer"
                        onClick={() => onRemoveSelectedDestination(destination)}
                      />
                    </div>
                  )}
                </div>
              </li>
            );
          })}
          {isSearchValueEmpty && (
            <li>
              <Text variant="body-16/r">{i18next.t('where.searchNoResult')}</Text>
            </li>
          )}
        </ul>
      </div>
    </SectionChildrenWrapper>
  );
};
