import React, { ReactNode, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';

import { Svg } from '@assets/svg';
import { Button, ButtonProps } from '@components/button';
import { ModalBackdrop } from '@components/modal/modalBackdrop/modalBackdrop.component';
import { ModalBody, ModalBodyProps, ModalComponentName } from '@components/modalBody';
import { Text, TextProps } from '@components/text';
import { Modal } from '@restart/ui';
import { useModalStore } from '@store/modal';

export interface FreelyModalProps {
  isOpen?: boolean;
  titleTextProps?: TextProps;
  bodyTextProps?: TextProps;
  titleClassName?: string;
  titleIcon?: ReactNode;
  body?: string | ModalBodyProps<ModalComponentName>;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  footer?: ReactNode;
  showCloseButton?: boolean;
  showButtonsInColumn?: boolean;
  actions?: ButtonProps[];
  onClose?: () => Promise<unknown> | void;
  bodyContainerClassName?: string;
  variant?: 'white' | 'mint' | 'nusa';
  children?: ReactNode;
}

export const sizecx = {
  sm: 'sm:w-[460px]',
  md: 'md:w-[630px]',
  lg: 'lg:w-[768px]',
  xl: 'xl:w-[1024px]',
};

export const variantColor = {
  white: 'white',
  mint: 'nusa-200',
  nusa: 'nusa-200',
};

export const FreelyModal: React.FC<FreelyModalProps> = ({
  titleTextProps,
  bodyTextProps,
  titleClassName = 'text-center',
  titleIcon = null,
  body,
  footer,
  size = 'sm',
  showCloseButton = true,
  showButtonsInColumn = false,
  actions,
  bodyContainerClassName = 'p-4',
  onClose,
  isOpen,
  children,
  variant = 'white',
}) => {
  const closeModal = useModalStore(state => state.closeModal);

  const handleClose = async (e: React.MouseEvent) => {
    e.stopPropagation();
    await onClose?.();
    closeModal();
  };

  const renderBackdrop = useCallback(
    (props: any) => <ModalBackdrop {...props} style={{ zIndex: 5002 }} />,
    [isOpen],
  );

  const renderModalBody = useCallback(() => {
    if (React.isValidElement(body)) {
      return <>{body}</>;
    }

    if (typeof body === 'string') {
      return <div>{body}</div>;
    }

    if (typeof body === 'object') {
      return (
        <>
          <ModalBody {...(body as ModalBodyProps<ModalComponentName>)} />
        </>
      );
    }
    return <>{null}</>;
  }, [body]);
  return (
    <Modal
      show={isOpen}
      container={document.getElementById('freely-widget')}
      className={twMerge('relative z-[5003]')}
      renderBackdrop={renderBackdrop}
      onBackdropClick={e => {
        e.stopPropagation();
        closeModal();
      }}
      aria-labelledby="modal-label"
      onHide={() => handleClose}>
      <div
        className={twMerge(
          'fixed md:rounded-b-2xl px-2 py-4 rounded-2xl rounded-b-none -translate-x-1/2 md:-translate-y-1/2 md:top-1/2 left-1/2 bottom-0 md:bottom-auto w-full',
          `bg-${variantColor[variant]}`,
          sizecx[size],
        )}>
        <div className={twMerge(`relative p-4`)}>
          {showCloseButton && (
            <div className={twMerge('absolute top-0 right-[2%]')}>
              <button
                tabIndex={-1}
                type="button"
                className={twMerge(
                  `focus:ring-nusa-200 rounded-md bg-${variantColor[variant]} text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2`,
                )}
                onClick={handleClose}>
                <span className={twMerge('sr-only')}>Close</span>
                <Svg.CloseCircleLight
                  className={twMerge('h-5 w-5')}
                  aria-hidden="true"
                  fill="black"
                />
              </button>
            </div>
          )}
          <div className={twMerge(bodyContainerClassName, titleClassName)}>
            {titleIcon && (
              <div className={twMerge('mx-auto mb-3 md:mb-4 w-12 md:w-16 lg:w-[72px]')}>
                {titleIcon}
              </div>
            )}
            {titleTextProps && (
              <Text className="leading-6" variant="h4-24/sb" {...titleTextProps} />
            )}
            <div className={twMerge('mt-4')}>
              {bodyTextProps && (
                <Text
                  variant={bodyTextProps.variant ?? 'body-16/m'}
                  withMarkDown
                  {...bodyTextProps}
                />
              )}
              {renderModalBody()}
            </div>
          </div>
          {footer
            ? footer
            : actions && (
                <div
                  className={twMerge(
                    'py-2',
                    !showButtonsInColumn && 'flex justify-between sm:space-x-4',
                    showButtonsInColumn && 'flex-col',
                  )}>
                  {actions.map(({ className, onClick, ...buttonProps }, index) => (
                    <Button
                      className={twMerge(
                        'mx-auto w-full transition-none active:scale-10',
                        showButtonsInColumn && 'mt-3',
                        className,
                      )}
                      key={index}
                      onClick={() => {
                        onClick?.();
                        closeModal();
                      }}
                      {...buttonProps}
                    />
                  ))}
                </div>
              )}
        </div>
        {children}
      </div>
    </Modal>
  );
};
