import { ModalProps } from '@restart/ui';

export const ModalBackdrop = (props: ModalProps) => (
  <div
    {...props}
    className={`fixed inset-0 bg-fuji-800 w-full h-full bg-opacity-25 z-[5000]`}
    style={{
      backdropFilter: 'blur(4px)',
      WebkitBackdropFilter: 'blur(4px)',
      ...props.style,
    }}
  />
);
