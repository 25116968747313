import { useState } from 'react';
import { createPortal } from 'react-dom';

import { checkSentryEnabled, toggleSentryEnabled } from 'freely-shared-utils';

import './style.css';

export const Debug = () => {
  const [isSentryEnabled, setIsSentryEnabled] = useState<boolean>(checkSentryEnabled());
  const [isVisible, setIsVisible] = useState(true);

  const handleToggleSentryEnabled = () => {
    toggleSentryEnabled();
    setIsSentryEnabled(checkSentryEnabled());
  };

  if (!isVisible) {
    return null;
  }

  return createPortal(
    <div className="debug-container">
      <div className="debug-header">
        <strong>Widget Debug Settings</strong>
        <button
          onClick={() => {
            setIsVisible(false);
          }}
          className="close-button">
          x
        </button>
      </div>
      <div>
        <label htmlFor="is-sentry-enabled">
          <input
            id="is-sentry-enabled"
            type="checkbox"
            name="isSentryEnabled"
            checked={isSentryEnabled}
            onChange={handleToggleSentryEnabled}
          />
          <span>Log error to Sentry</span>
        </label>
      </div>
    </div>,
    document.body,
    'debug-portal',
  );
};
