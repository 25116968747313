import { FC, HTMLAttributes, createElement } from 'react';
import { twMerge } from 'tailwind-merge';
import { type VariantProps, tv } from 'tailwind-variants';

import { CustomMarkdown } from './markdown.component';

export const textTypography = tv({
  variants: {
    variant: {
      'h1-44': 'text-[2.75rem]/[3.25rem]',
      'h2-36': 'text-[2.25rem]/[2.75rem]',
      'h3-28': 'text-[1.75rem]/[2.25rem]',
      'h4-24': 'text-[1.5rem]/[2rem]',
      'subTitle-20': 'text-[1.25rem]/[1.75rem]',
      'body-16': 'text-[1rem]/[1.5rem]',
      'subHeading-14': 'text-[0.875rem]/[1.25rem]',
      'footnote-12': 'text-[0.75rem]/[1rem]',
    },
    weight: {
      r: 'font-normal',
      m: 'font-medium',
      sb: 'font-semibold',
      freely: 'font-normal font-[Freely-B5]',
    },
  },
  defaultVariants: {
    variant: 'body-16',
    weight: 'r',
  },
});

type Strict<T> = {
  [K in keyof T]-?: Exclude<T[K], undefined>;
};

type FontVariantProps = VariantProps<typeof textTypography>;

// Type for the combined variant and weight
// Strict is used to remove undefined from the type
export type FontVariant =
  `${Strict<FontVariantProps>['variant']}/${Strict<FontVariantProps>['weight']}`;

export interface ResponsiveFontVariant {
  sm: FontVariant;
  md?: FontVariant;
  lg: FontVariant;
}

// Function to determine the HTML element name based on variant
const getElementName = (variant?: FontVariantProps['variant']) => {
  if (variant?.startsWith('h')) {
    return variant.substring(0, 2) as keyof JSX.IntrinsicElements;
  } else {
    return 'p';
  }
};

export interface TextProps extends HTMLAttributes<HTMLHeadingElement> {
  variant?: FontVariant | ResponsiveFontVariant;
  withMarkDown?: boolean;
  onClick?: (() => Promise<void>) | (() => void);
}

export const Text: FC<TextProps> = ({
  variant = 'body-16/r',
  children,
  style,
  withMarkDown,
  className,
  ...rest
}) => {
  if (typeof variant === 'string') {
    const [sizeVariant, weightVariant] = variant.split('/') as [
      FontVariantProps['variant'],
      FontVariantProps['weight'],
    ];

    const content = withMarkDown ? <CustomMarkdown>{children as string}</CustomMarkdown> : children;
    const textVariant = textTypography({
      variant: sizeVariant,
      weight: weightVariant,
    });
    const newClassName = twMerge(textVariant, className);

    const elementName = getElementName(sizeVariant);

    return createElement(
      elementName,
      {
        className: newClassName,
        style: style,
        ...rest,
      },
      content,
    );
  }

  const { sm, md, lg } = variant as ResponsiveFontVariant;

  const responsiveSizes = [
    { size: sm, class: twMerge('md:hidden', className) },
    { size: md ?? lg, class: twMerge('hidden md:block lg:hidden', className) },
    { size: lg, class: twMerge('hidden lg:block', className) },
  ];

  return (
    <>
      {responsiveSizes.map(({ size, class: responsiveClass }, index) => {
        if (size) {
          const [sizeVariant, weightVariant] = size.split('/') as [
            FontVariantProps['variant'],
            FontVariantProps['weight'],
          ];
          const elementName = getElementName(sizeVariant);

          const textVariant = textTypography({
            variant: sizeVariant,
            weight: weightVariant,
          });

          const currentClassName = twMerge(textVariant, responsiveClass);
          const content = withMarkDown ? (
            <CustomMarkdown>{children as string}</CustomMarkdown>
          ) : (
            children
          );
          return createElement(
            elementName,
            {
              key: index,
              className: currentClassName,
              style: { ...style },
              ...rest,
            },
            content,
          );
        }
        return null;
      })}
    </>
  );
};
