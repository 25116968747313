import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { Svg } from '@assets/svg';
import { Button } from '@components/button';
import { Text } from '@components/text';

export type MaintenanceProps = {
  children?: ReactNode;
};
export const Maintenance = () => {
  const assistanceNumber = '+61 2 8907 5987';

  return (
    <div
      className={twMerge(
        'bg-nusa-200 flex h-full w-full flex-col items-center justify-center space-y-7 px-4 text-center',
      )}>
      <Svg.Tools />
      <Text variant="h2-36/sb">Quotes under maintenance...</Text>
      <div className={twMerge('border-b border-fuji-800 border-opacity-10 py-0 pb-6 px-2')}>
        <Text variant="body-16/r">
          Our quotes is under maintenance right now. It’ll be back up soon. Please try again later.
        </Text>
      </div>
      <Text variant="body-16/r" className={twMerge('py-1')}>
        Call us if you need Emergency Assistance:
        <a href={`tel:${assistanceNumber.replace(/\s+/g, '')}`} className={twMerge('block mt-5')}>
          <Button variant="white">{assistanceNumber}</Button>
        </a>
      </Text>
    </div>
  );
};
