export const colors = {
  // Premitive colors freely core v2

  nusa: {
    50: '#E9FFF7',
    100: '#CBFFE9',
    200: '#AAFFDE',
    300: '#5BFAC6',
    400: '#1BECAF',
    500: '#00D499',
    600: '#00AD7F',
    700: '#008A69',
    800: '#006D54',
    900: '#005946',
    950: '#003329',
  },
  cabo: {
    50: '#F6F8F9',
    100: '#EBEFF3',
    200: '#D3DCE4',
    300: '#ACBECD',
    400: '#809CB0',
    500: '#608097',
    600: '#506D85',
    700: '#3E5366',
    800: '#364756',
    900: '#313D49',
  },

  fuji: {
    50: '#BBBEC3',
    100: '#9FA6AE',
    200: '#838C97',
    300: '#667382',
    400: '#526274',
    500: '#3C4F63',
    600: '#2F4153',
    700: '#212F3E',
    800: '#131E29',
    900: '#000814',
  },
  nevada: {
    50: '#F6F7F6',
    100: '#F3F4F3',
    200: '#EFF0EF',
    300: '#E9EBE9',
    400: '#E2E5E2',
    500: '#D8DCD8',
    600: '#B6BDB6',
    700: '#98A398',
    800: '#7F8C7F',
    900: '#6C786C',
    950: '#4E574E',
  },
  santorini: {
    50: '#F2F6FC',
    100: '#E1EBF8',
    200: '#C9DCF4',
    300: '#A4C6EC',
    400: '#78A7E2',
    500: '#5F8DDA',
    600: '#446ECC',
    700: '#3B5BBA',
    800: '#354B98',
  },

  green: {
    50: '#EDFFED',
    200: '#C3EFC6',
    300: '#95E09B',
    400: '#5FC968',
    500: '#3BB545',
    600: '#2A8F32',
    700: '#24712B',
    800: '#215A27',
    900: '#1D4A22',
  },

  red: {
    50: '#FFF1F0',
    200: '#FFC5C3',
    300: '#FF9E9A',
    400: '#FF655F',
    500: '#FF352E',
    600: '#F41D15',
    700: '#CD0F08',
    800: '#A9110B',
    900: '#8C1410',
  },

  orange: {
    50: '#FFF4E5',
    200: '#FFD8A5',
    300: '#FFBC6D',
    400: '#FF9432',
    500: '#FF750A',
    600: '#FE5B00',
    700: '#CC4002',
    800: '#A1320B',
    900: '#822C0C',
  },

  mono: {
    100: '#FFFFFF',
    200: '#F2F2F2',
    300: '#E5E5E5',
    400: '#B2B2B2',
    500: '#8C8C8C',
    600: '#595959',
    700: '#404040',
    800: '#262626',
    900: '#000000',
  },
} as const;
