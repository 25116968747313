import { SyntheticEvent, forwardRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';

import { ButtonVariant } from 'freely-shared-types';

import { ButtonSize } from '@types';

import { Loader } from '../loader';
import { Text, TextProps } from '../text';

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  fontVariant?: TextProps['variant'];
  withFixedWith?: boolean;
  withEllipsis?: boolean;
  withWordWrap?: boolean;
  isJumboSize?: boolean;
  variant?: ButtonVariant;
  size?: ButtonSize;
  IconLeft?: React.FC<React.SVGProps<SVGElement>>;
  IconRight?: React.FC<React.SVGProps<SVGElement>>;
  IconRightProps?: React.SVGProps<SVGElement>;
  IconLeftProps?: React.SVGProps<SVGElement>;
  disabled?: boolean;
  isLoading?: boolean;
  textAlign?: 'left' | 'right' | 'center';
  onClick?: () => Promise<unknown> | void;
  type?: 'button' | 'submit' | 'reset';
  shouldShowLoader?: boolean;
  isStoppingPropagation?: boolean;
}

export const buttonVariants = tv({
  base: 'duration-400 relative rounded-full transition active:scale-105',
  variants: {
    variant: {
      mint: 'bg-nusa-200 disabled:text-mono-100 disabled:bg-mono-300 hover:bg-nusa-100',
      charcoal:
        'bg-fuji-800 [&>p]:text-mono-100 disabled:text-mono-100 disabled:bg-fuji-800/60 hover:bg-fuji-800/80',
      snow: 'bg-cabo-50 disabled:text-fuji-800/50 disabled:bg-cabo-50/60 hover:bg-cabo-50/80',
      white: 'bg-mono-100 disabled:text-fuji-800/50 disabled:bg-mono-100/60 hover:bg-mono-100/80 ',
      cherry:
        'bg-red-500 text-mono-100 disabled:text-mono-100 disabled:bg-red-500 focus:bg-red-500',
      sunset: 'bg-orange-50 text-fuji-800',
      cabo: 'bg-cabo-50',
      fuji: 'bg-fuji-800  [&>p]:text-mono-100 ',
      outline:
        'bg-mono-100 ring-1 ring-inset ring-fuji-800 disabled:text-fuji-800/50 disabled:bg-mono-100/60 hover:bg-mono-100/80',
    },
    size: {
      xl: 'px-8 py-1 h-16',
      lg: 'px-8 py-1 h-16',
      md: 'px-5 py-1 h-12',
      sm: 'px-3 py-1 h-8',
      xs: 'px-3 h-6',
    },
  },
});

const getFontVariant: (size?: ButtonSize) => TextProps['variant'] = size => {
  switch (size) {
    case 'xl':
      return 'h4-24/sb';
    case 'lg':
      return 'subTitle-20/sb';
    case 'md':
      return 'body-16/sb';
    case 'sm':
      return 'subHeading-14/r';
    case 'xs':
      return 'footnote-12/r';
  }
};

export const iconVariants = tv({
  variants: {
    size: {
      xl: 'max-w-[2rem]',
      lg: 'max-w-[2rem]',
      md: 'max-w-[1.25rem]',
      sm: 'max-w-[1rem]',
      xs: 'max-w-[1rem]',
    },
  },
});

export const textAlignVariant = tv({
  variants: {
    textAlign: {
      left: 'text-left',
      right: 'text-right',
      center: 'text-center',
    },
  },
});

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      textAlign = 'center',
      size = 'md',
      fontVariant,
      variant,
      IconLeft,
      IconRight,
      IconLeftProps,
      IconRightProps,
      disabled = false,
      isLoading = false,
      withFixedWith,
      withWordWrap = false,
      isJumboSize,
      withEllipsis,
      onClick,
      type = 'button',
      shouldShowLoader = true,
      isStoppingPropagation = true,
      ...rest
    },
    ref,
  ) => {
    const [isPending, setIsPending] = useState<boolean>(false);
    const isLoadingSpinnerDisplayed = isPending || isLoading;

    const onButtonClick = async (e: SyntheticEvent) => {
      type === 'submit' && e.preventDefault();
      isStoppingPropagation && e.stopPropagation();
      try {
        shouldShowLoader && setIsPending(true);
        await onClick?.();
        shouldShowLoader && setIsPending(false);
      } catch (e) {
        // sometimes callbacks can throw errors, we don't want to show the loader forever
      } finally {
        shouldShowLoader && setIsPending(false);
      }
    };

    return (
      <button
        type={type}
        ref={ref}
        onClick={onButtonClick}
        disabled={disabled || isLoadingSpinnerDisplayed}
        className={twMerge(
          buttonVariants({ variant, size }),
          withFixedWith && 'w-11/12 lg:w-52',
          isJumboSize && 'h-20 w-full rounded-[1.5rem]',
          IconRight && 'flex justify-between items-center space-x-0.5',
          className,
        )}
        {...rest}>
        {IconLeft && (
          <IconLeft
            {...IconLeftProps}
            fill={getIconFill(isLoadingSpinnerDisplayed, IconLeftProps)}
            className={twMerge(
              iconVariants({ size }),
              'absolute top-1/2 left-3.5 translate-y-[-50%]',
              IconLeftProps?.className,
            )}
          />
        )}
        {isLoadingSpinnerDisplayed && (
          <div
            className={twMerge(
              'absolute top-1/2 left-1/2 z-10 translate-y-[-50%] translate-x-[-50%]',
              iconVariants({ size }),
            )}>
            <Loader color={getLoaderColor(variant)} />
          </div>
        )}
        <Text
          className={twMerge(
            !withWordWrap && 'whitespace-nowrap',
            isLoadingSpinnerDisplayed && '!text-transparent',
            textAlignVariant({ textAlign }),
            IconLeft && 'ml-5 md:ml-2.5',
            IconRight && (String(IconRightProps?.style) ?? 'mr-2.5'),
            withEllipsis && 'overflow-hidden text-ellipsis',
          )}
          variant={fontVariant ?? getFontVariant(size)}>
          {children}
        </Text>
        {IconRight && (
          <IconRight
            {...IconRightProps}
            fill={getIconFill(isLoadingSpinnerDisplayed, IconRightProps)}
            className={twMerge(iconVariants({ size }), IconRightProps?.className)}
          />
        )}
      </button>
    );
  },
);

const getLoaderColor = (variant: ButtonVariant | undefined) => {
  return variant === 'charcoal' || variant === 'fuji' ? 'white' : 'charcoal';
};
const getIconFill = (
  isLoadingSpinnerDisplayed: boolean,
  iconProps: React.SVGProps<SVGElement> | undefined,
) => {
  return isLoadingSpinnerDisplayed ? 'transparent' : iconProps?.fill;
};

Button.displayName = 'Button';
