import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';
import { twMerge } from 'tailwind-merge';

import { useClickOutsideHandler } from '@hooks/useClickOutsideHandler';
import { useSyncQuoteStoreWithForm } from '@hooks/useSyncQuoteStoreWithForm.ts';
import { SectionType } from '@types';
import { sendAnalyticsPage } from '@utils/analytics.ts';

export type ChildrenProps = {
  isFocused: boolean;
  onFocus: () => void;
  onClose?: () => void;
};

export interface SectionContainerProps {
  children: ({ isFocused, onFocus, onClose }: ChildrenProps) => React.ReactNode;
  className?: string;
  setIsModalVisible?: (isModalVisible: boolean) => void;
  isModalVisible?: boolean;
  sectionType: SectionType;
}

export const SectionContainer = ({ className, children, sectionType }: SectionContainerProps) => {
  const syncQuoteStore = useSyncQuoteStoreWithForm();
  const { isModalVisible, setIsModalVisible, ref, onClose } = useClickOutsideHandler(sectionType);

  return (
    <div
      onBlur={syncQuoteStore}
      ref={ref}
      className={twMerge([
        'mb-5 lg:flex rounded-2xl py-5 lg:py-0 bg-cabo-50 border-transparent border lg:border-2 lg:px-0 lg:shadow-none w-full lg:items-center',
        isModalVisible &&
          '!bg-mono-100 shadow-lg border-cabo-200 lg:shadow-none lg:border-nusa-200',
        className,
      ])}>
      <div className={twMerge('w-full px-4 lg:px-2')}>
        {children({
          isFocused: isModalVisible,
          onFocus: () => {
            setIsModalVisible(true);
            sendAnalyticsPage(`${startCase(camelCase(sectionType))} Widget Section Viewed`);
          },
          onClose: onClose,
        })}
      </div>
    </div>
  );
};
