import { MouseEvent } from 'react';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';

import { Svg } from '@assets/svg';
import { Text } from '@components/text';

type StepperSize = 'sm' | 'md' | 'lg';

export interface StepperProps {
  maxNumber: number;
  onRemovePress?: () => void;
  onAddPress?: () => void;
  disabled?: boolean;
  size?: StepperSize;
  className?: string;
  currentNumber: number;
}

export const iconVariants = tv({
  variants: {
    size: {
      lg: '45px',
      md: '40px',
      sm: '32px',
    },
  },
});
const getFontVariant = (size: StepperSize = 'md') => {
  switch (size) {
    case 'lg':
      return 'body-16/r';
    case 'md':
      return 'body-16/r';
    case 'sm':
      return 'subHeading-14/r';
  }
};
export const Stepper = ({
  maxNumber,
  currentNumber,
  onRemovePress,
  onAddPress,
  disabled = false,
  size = 'md',
  className,
}: StepperProps) => {
  const removeIconStatus = currentNumber > 0;
  const addIconStatus = currentNumber < maxNumber && !disabled;

  const onRemoveClick = (event: MouseEvent<SVGAElement>) => {
    event.stopPropagation();
    if (removeIconStatus) {
      onRemovePress?.();
    }
  };

  const onAddClick = (event: MouseEvent<SVGAElement>) => {
    event.stopPropagation();
    if (addIconStatus) {
      onAddPress?.();
    }
  };

  return (
    <div className={twMerge('flex items-center space-x-5', className)}>
      <Svg.StepperSVGIcon
        type="remove"
        disabled={!removeIconStatus}
        onClick={onRemoveClick}
        className="cursor-pointer"
      />
      <div className={twMerge('w-[1rem] text-center')}>
        <Text variant={getFontVariant(size)}>{currentNumber}</Text>
      </div>
      <Svg.StepperSVGIcon
        type="add"
        disabled={!addIconStatus}
        onClick={onAddClick}
        className="cursor-pointer"
      />
    </div>
  );
};
