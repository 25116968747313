import { useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { colors } from 'freely-shared-design';
import { regionDateUtils, useConfigStore } from 'freely-shared-stores';
import { TravellersForm } from 'freely-shared-types';
import { getTravellerAgeType } from 'freely-shared-utils';

import { Svg } from '@assets/svg';
import { Text } from '@components/text';

import { IsDependencyCheckBox } from './isDependencyCheckBox.component';

type Props = {
  index: number;
};

const SecondaryTravellerDependencyComponent = {
  ['Young Adult']: IsDependencyCheckBox,
  ['Child']: IsDependencyCheckBox,
  ['Adult']: ReturnNull,
};

export function DependencyCheckBox({ index }: Props) {
  const {
    watch,
    formState: { errors },
  } = useFormContext<TravellersForm>();

  const regionSpecificConfig = useConfigStore(state => state?.regionSpecificConfig);
  const residentCheckBoxStatus = regionSpecificConfig?.RULES.RESIDENT_CHECKBOX;
  const secondaryTravellerErrors = errors.secondaryTravellers?.[index];
  const secondaryTravellerAgeRules = regionSpecificConfig?.RULES.SECONDARY_TRAVELLER_AGE ?? {
    min: 0,
    mid: { min: 18, max: 21 },
    max: 99,
  };
  const dob = watch(`secondaryTravellers.${index}.dob`) ?? '';
  const dobError = secondaryTravellerErrors?.['dob'];

  if (dobError) {
    return (
      <div>
        <div className="border w-[95%] m-[0.7rem]" />
        <div className={twMerge('flex items-center h-9')}>
          <Svg.Alert width={18} fill={colors.red[500]} className="ml-2" />
          <Text variant={'subHeading-14/r'} className="ml-[0.8rem] my-[1rem] text-red-500">
            {dobError?.message}
          </Text>
        </div>
      </div>
    );
  }

  if (!residentCheckBoxStatus || !dob) {
    return null;
  }

  const age = regionDateUtils().getAge(dob);
  const travellerType = getTravellerAgeType(age, secondaryTravellerAgeRules);
  const DependencyComponent = SecondaryTravellerDependencyComponent?.[travellerType] ?? ReturnNull;

  return <DependencyComponent index={index} />;
}

function ReturnNull() {
  return null;
}
