import { Controller, useFormContext } from 'react-hook-form';

import { colors } from 'freely-shared-design';
import { TravellersForm } from 'freely-shared-types';

import { Svg } from '@assets/svg';
import { Checkbox } from '@components/checkbox';
import { useTravellerInfoModal } from '@hooks/useTravellerInfoModal.ts';
import { i18n } from '@i18n/i18n';

type Props = {
  index: number;
};

export function IsDependencyCheckBox({ index }: Props) {
  const { control } = useFormContext<TravellersForm>();
  const isDependantId = `secondaryTravellers.${index}.isDependant` as never;
  const { openDependantInfoModal } = useTravellerInfoModal();

  return (
    <div>
      <div className="border w-[95%] m-[0.7rem]" />
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row h-9 items-center">
          <Controller
            control={control}
            name={isDependantId}
            render={({ field }) => (
              <Checkbox
                isChecked={field.value}
                labelProps={{
                  children: i18n.t('who.additionTravellers.checkbox.title'),
                  variant: 'subHeading-14/r',
                }}
                onClick={() => {
                  field.onChange(!field.value);
                }}
              />
            )}
          />
        </div>
        <div className="mr-[1rem]">
          <button type="button" onClick={openDependantInfoModal}>
            <Svg.InfoCircleBlue width={20} height={20} fill={colors.nusa[200]} />
          </button>
        </div>
      </div>
    </div>
  );
}
