/**
 * More Details Swiper Configuration https://swiperjs.com/react
 */
import { useCallback, useRef, useState } from 'react';
import { Swiper as SwiperType } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper as NewSwiper, SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper/types';
import { twMerge } from 'tailwind-merge';

import { colors } from 'freely-shared-design';
import { ButtonVariant, TripDestination } from 'freely-shared-types';

import { Svg } from '@assets/svg';
import { ButtonSize, QuickSelectOption } from '@types';
import { isDestination, isQuickSelectOption } from '@utils/swiper';

import { Button, ButtonProps } from '../button';

export type SlidesType = TripDestination | QuickSelectOption;

export interface SwiperProps {
  swiperConfig: SwiperOptions;
  buttonProps?: ButtonProps;
  slides: SlidesType[];
  onClick?: (data: TripDestination | QuickSelectOption) => void;
  selectedIndex?: number;
  isLoading?: boolean;
}

export const Swiper = ({
  swiperConfig,
  buttonProps,
  slides,
  onClick,
  selectedIndex,
  isLoading,
}: SwiperProps) => {
  const swiperRef = useRef<SwiperType>();
  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const slidesPerView =
    swiperConfig.breakpoints?.[640]?.slidesPerView ?? swiperConfig.slidesPerView;
  const { size } = buttonProps ?? { size: 'sm' };

  const defaultParams: SwiperOptions = {
    spaceBetween: 10,
    modules: [Navigation],
    slidesPerView: slidesPerView,
  };
  const swiperParams = { ...defaultParams, ...swiperConfig };

  const handlePrev = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.slideNext();
  }, []);

  return (
    <div className={twMerge('mb-6 last:mb-0')}>
      <div className={twMerge('relative')}>
        <NewSwiper
          onBeforeInit={swiper => {
            swiperRef.current = swiper;
          }}
          onReachEnd={() => {
            setIsLastSlide(true);
          }}
          onSlideChange={() => {
            if (!swiperRef.current?.isBeginning && isFirstSlide) {
              setIsFirstSlide(false);
            }
            if (!swiperRef.current?.isEnd && isLastSlide) {
              setIsLastSlide(false);
            }
          }}
          onReachBeginning={() => {
            setIsFirstSlide(true);
          }}
          {...swiperParams}>
          {slides.map((slide, index) => {
            let buttonText;
            let buttonClassName = '';
            let buttonVariant: ButtonVariant = 'cabo';

            let size: ButtonSize = 'sm';
            if (isDestination(slide)) {
              buttonText = slide.longName;
            }

            if (isQuickSelectOption(slide)) {
              buttonText = slide.amount ? `+ ${slide.amount} ${slide.label}` : slide.label;
              size = 'xs';
              if (selectedIndex === slide.index) {
                buttonClassName = 'border-fuji-800 border-2';
                buttonVariant = 'white';
              }
            }

            return (
              <SwiperSlide key={index} className={twMerge('w-fit', isLoading && 'w-28')}>
                <Button
                  size={size}
                  fontVariant="footnote-12/m"
                  variant={buttonVariant}
                  {...buttonProps}
                  className={twMerge([
                    'w-full text-left cursor-pointer px-4 h-8',
                    isLoading && 'animate-pulse dark:bg-cabo-100 cursor-progress',
                    buttonClassName,
                  ])}
                  disabled={isLoading}
                  onClick={() => onClick?.(slide)}>
                  {buttonText}
                </Button>
              </SwiperSlide>
            );
          })}
        </NewSwiper>
        <div
          className={twMerge([
            'absolute z-10 -left-5 after:content-[" "] after:h-10 after:w-14 h-10 flex items-center top-0 after:bg-gradient-to-r after:from-mono-100 after:from-70% hidden lg:block after:hidden lg:after:block',
            size === 'xs' && '!h-6',
            isFirstSlide && 'lg:hidden',
          ])}>
          <Svg.ArrowLeft
            fill={`${colors.fuji[800]}`}
            onClick={handlePrev}
            className={twMerge([
              'absolute left-4 h-4 w-4 top-2 cursor-pointer',
              size === 'xs' && '!h-3 !w-3',
            ])}
          />
        </div>
        <div
          className={twMerge([
            'absolute z-10 -right-4 after:content-[" "] after:h-10 after:w-16 h-10 flex items-center top-0 after:bg-gradient-to-l after:from-mono-100 after:from-70% hidden lg:block after:hidden lg:after:block',
            size === 'xs' && '!h-6',
            isLastSlide && 'lg:hidden',
          ])}>
          <Svg.ArrowRight
            fill={`${colors.fuji[800]}`}
            onClick={handleNext}
            className={twMerge([
              'absolute right-4 h-4 w-4 top-2 cursor-pointer',
              size === 'xs' && '!h-3 !w-3',
            ])}
          />
        </div>
      </div>
    </div>
  );
};
