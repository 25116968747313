import { twMerge } from 'tailwind-merge';

import { useConfigStore } from 'freely-shared-stores';

import { EditTravellers, ResidentStatus } from '@components/editTravellers';
import { PortalWrapper } from '@components/portalWrapper';
import { ScrollWrapper } from '@components/scrollWrapper';
import { SectionContainer } from '@components/sectionContainer';
import { TravellerSection } from '@components/traveller/travellerSection.component';

export interface TravellersProps {
  travellerPortalRef: React.RefObject<HTMLDivElement>;
}
export const Travellers: React.FC<TravellersProps> = ({ travellerPortalRef }) => {
  const isResidencyCheckboxEnabled = useConfigStore(
    state => !!state?.regionSpecificConfig?.RULES.RESIDENT_CHECKBOX,
  );

  return (
    <SectionContainer sectionType="travellers">
      {({ isFocused, onFocus }) => (
        <>
          <TravellerSection isFocused={isFocused} onFocus={onFocus} />
          <PortalWrapper portalRef={travellerPortalRef} isFocused={isFocused}>
            <ScrollWrapper>
              <div
                className={twMerge([
                  'lg:border z-[100] lg:border-cabo-200 lg:relative lg:shadow-lg rounded-2xl bg-mono-100 lg:w-[36%] lg:inset-x-[calc(100%_-_36%)] mt-2 mb-6',
                ])}>
                <EditTravellers />
                {isResidencyCheckboxEnabled ? <ResidentStatus /> : null}
              </div>
            </ScrollWrapper>
          </PortalWrapper>
        </>
      )}
    </SectionContainer>
  );
};
